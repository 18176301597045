// Generated by Framer (4d5c76d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Link, Text, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import NavbarIcon from "./Wk54GqMqh";
const NavbarIconFonts = getFonts(NavbarIcon);

const enabledGestures = {M_KMqyyi_: {hover: true}};

const cycleOrder = ["M_KMqyyi_"];

const serializationHash = "framer-EGefU"

const variantClassNames = {M_KMqyyi_: "framer-v-lmgl0f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, tap, title, width, ...props}) => { return {...props, Kv6QUiM48: title ?? props.Kv6QUiM48 ?? "Animation", n29_AKCa8: link ?? props.n29_AKCa8, PbNgnLNYz: tap ?? props.PbNgnLNYz} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, n29_AKCa8, Kv6QUiM48, PbNgnLNYz, w0TwmQycCq4_MKy48G, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "M_KMqyyi_", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1i5pbr7 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (PbNgnLNYz) {
const res = await PbNgnLNYz(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (gestureVariant === "M_KMqyyi_-hover") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={n29_AKCa8} openInNewTab={false} smoothScroll><motion.a {...restProps} animate={variants} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-lmgl0f", className, classNames)} framer-lbcyh6`} data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"M_KMqyyi_"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1i5pbr7} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"M_KMqyyi_-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Text __fromCanvasComponent alignment={"left"} className={"framer-11846bz"} data-framer-name={"Explore"} fonts={["GF;DM Sans-regular"]} layoutDependency={layoutDependency} layoutId={"ZFkTXqiwm"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Animation</span><br></span></span>"} style={{"--framer-font-family": "\"DM Sans\", \"DM Sans Placeholder\", sans-serif", "--framer-font-size": "17px", "--framer-font-style": "normal", "--framer-font-weight": 400, "--framer-letter-spacing": "0em", "--framer-line-height": "20px", "--framer-text-alignment": "left", "--framer-text-color": "var(--token-2db9bf47-d5e9-425b-9cac-5ab8fb30c3fd, rgb(9, 11, 20))", "--framer-text-decoration": "none", "--framer-text-transform": "none"}} text={Kv6QUiM48} verticalAlignment={"top"} withExternalLayout/>{isDisplayed() && (<motion.div className={"framer-q7hi6a-container"} layoutDependency={layoutDependency} layoutId={"NBBVgKsOQ-container"}><NavbarIcon height={"100%"} id={"NBBVgKsOQ"} layoutId={"NBBVgKsOQ"} variant={w0TwmQycCq4_MKy48G} width={"100%"}/></motion.div>)}</motion.a></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-EGefU[data-border=\"true\"]::after, .framer-EGefU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EGefU.framer-lbcyh6, .framer-EGefU .framer-lbcyh6 { display: block; }", ".framer-EGefU.framer-lmgl0f { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 8px 12px 8px 12px; position: relative; text-decoration: none; width: 208px; }", ".framer-EGefU .framer-11846bz { -webkit-user-select: none; flex: none; height: auto; overflow: visible; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-EGefU .framer-q7hi6a-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-EGefU.framer-lmgl0f { gap: 0px; } .framer-EGefU.framer-lmgl0f > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-EGefU.framer-lmgl0f > :first-child { margin-left: 0px; } .framer-EGefU.framer-lmgl0f > :last-child { margin-right: 0px; } }", ".framer-EGefU.framer-v-lmgl0f.hover .framer-11846bz { order: 2; }", ".framer-EGefU.framer-v-lmgl0f.hover .framer-q7hi6a-container { order: 0; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 208
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"nrjCDf4Q2":{"layout":["fixed","auto"]}}}
 * @framerVariables {"n29_AKCa8":"link","Kv6QUiM48":"title","PbNgnLNYz":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerk_myTKw3n: React.ComponentType<Props> = withCSS(Component, css, "framer-EGefU") as typeof Component;
export default Framerk_myTKw3n;

Framerk_myTKw3n.displayName = "Projects Inside Nav";

Framerk_myTKw3n.defaultProps = {height: 36, width: 208};

addPropertyControls(Framerk_myTKw3n, {n29_AKCa8: {title: "Link", type: ControlType.Link}, Kv6QUiM48: {defaultValue: "Animation", displayTextArea: false, title: "Title", type: ControlType.String}, PbNgnLNYz: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Framerk_myTKw3n, [{family: "DM Sans", style: "normal", url: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxhS2f3ZGMZpg.woff2", weight: "400"}, ...NavbarIconFonts])